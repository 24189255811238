<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space homeBanner bg-white">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="zuluGuard text-center col-12 col-lg-5"> <img src="/assets/images/hero-banner.png" :alt="$t('zulutradeData.dataText1')" :title="$t('zulutradeData.dataText1')"> </div>
                    <div class="col-12 col-lg-7">
                        <h1 class="bold mb-md-4">{{$t('zulutradeData.dataText2')}}</h1>
                        <ul class="list-arrow">
                            <li class="mb-4">{{$t('zulutradeData.dataText3')}}</li>
                            <li>{{$t('zulutradeData.dataText4')}} </li>
                        </ul>
                        <div class="dualButton my-md-5">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5 ms-3">{{$t('zulutradeData.dataText5')}}</router-link>
                            <a v-else :href="'/register/'" target="_blank" class="button fillBtn zulu_btn me-3">{{$t('zulutradeData.dataText5')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space overflow-hidden">
            <div class="container max-970">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4 text-center">{{$t('zulutradeData.dataText6')}}</h2>
                    </div>
                    <div class="d-flex mb-2 align-items-center justify-content-end"> <span class="text">{{$t('zulutradeData.dataText7')}}</span> <label class="switch mx-2"> <input type="checkbox" v-on:click="isHidden = !isHidden"> <span class="slider round"></span> </label> <span class="text">{{$t('zulutradeData.dataText8')}}</span>
                        <div class="position-relative ms-1 mt-1">
                            <div class="tooltipbutton"> <span class="tooltiptext">{{$t('zulutradeData.dataText9')}}  </span>
                                <i class="smallSize" data-feather="alert-circle"></i>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card mb-5" v-bind:class="[{ flipped: isHidden }]">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <table class="wl-table">
                                    <thead>
                                        <tr>
                                            <th> </th>
                                            <th>{{$t('zulutradeData.dataText10')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText101')}}</span></th>
                                            <th> {{$t('zulutradeData.dataText11')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText102')}}</span></th>
                                            <th> {{$t('zulutradeData.dataText12')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText103')}}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">
                                                <p class="mb-0">{{$t('zulutradeData.dataText13')}}</p>
                                                <div style="font-size: 12px; font-weight: 600; line-height: 14px; margin-top: 5px;"> {{$t('zulutradeData.dataText14')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText15')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText16')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText17')}} </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText18')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText19')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText20')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText21')}} </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="flip-card-back" id="Hands-off">
                                <table class="wl-table">
                                    <thead>
                                        <tr>
                                            <th> </th>
                                            <th>{{$t('zulutradeData.dataText22')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText104')}}</span> </th>
                                            <th>{{$t('zulutradeData.dataText23')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText105')}}</span> </th>
                                            <th>{{$t('zulutradeData.dataText24')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText106')}}</span> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">
                                                <div> <span>{{$t('zulutradeData.dataText13')}}</span> </div>
                                                <div style="font-size: 12px; font-weight: 600; line-height: 14px; margin-top: 5px;"> {{$t('zulutradeData.dataText26')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText27')}}</span></div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText28')}}</span></div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText29')}}</span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                                <div> <span>{{$t('zulutradeData.dataText30')}}</span> </div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText31')}}</span></div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText32')}}</span></div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText33')}}</span></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <h3>{{$t('zulutradeData.dataText13')}}</h3>
                                <h4>{{$t('zulutradeData.dataText35')}}</h4>
                                <p>{{$t('zulutradeData.dataText36')}}</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <h3> {{$t('zulutradeData.dataText37')}}</h3>
                                <h4>{{$t('zulutradeData.dataText38')}}</h4>
                                <p>{{$t('zulutradeData.dataText39')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="dualButton text-center my-md-5">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5 ms-3">{{$t('zulutradeData.dataText40')}}</router-link>
                        <a v-else :href="'/register/'" target="_blank" class="button fillBtn zulu_btn me-3">{{$t('zulutradeData.dataText40')}}</a>
                    </div>
                </div>
            </div>
        </section>

        <section class="blockElement space overflow-hidden">
            <div class="container max-970">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4 text-center">{{$t('zulutradeData.dataText41')}}</h2>
                    </div>
                    <div class="d-flex mb-2 align-items-center justify-content-end"> <span class="text">{{$t('zulutradeData.dataText42')}}</span> <label class="switch mx-2"> <input type="checkbox" v-on:click="isHidden2 = !isHidden2"> <span class="slider round"></span> </label> <span class="text">{{$t('zulutradeData.dataText43')}}</span>
                        <div class="position-relative ms-1 mt-1">
                            <div class="tooltipbutton"> <span class="tooltiptext">{{$t('zulutradeData.dataText44')}} </span>                        <i class="smallSize" data-feather="alert-circle"></i> </div>
                        </div>
                    </div>
                    <div class="flip-card mb-5" v-bind:class="[{ flipped: isHidden2 }]">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <table class="wl-table">
                                    <thead>
                                        <tr>
                                            <th> </th>
                                            <th>{{$t('zulutradeData.dataText45')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText107')}}</span> </th>
                                            <th>{{$t('zulutradeData.dataText46')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText108')}}</span> </th>
                                            <th>{{$t('zulutradeData.dataText47')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText109')}}</span> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText48')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div> {{$t('zulutradeData.dataText49')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText50')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText51')}} </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText52')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText53')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText54')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText55')}} </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText56')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText57')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText58')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText59')}} </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="flip-card-back">
                                <table class="wl-table">
                                    <thead>
                                        <tr>
                                            <th> </th>
                                            <th>{{$t('zulutradeData.dataText60')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText110')}}</span> </th>
                                            <th>{{$t('zulutradeData.dataText61')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText111')}}</span> </th>
                                            <th>{{$t('zulutradeData.dataText62')}} <br> <span class="small inactive">{{$t('zulutradeData.dataText113')}}</span> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">
                                                <div> <span>{{$t('zulutradeData.dataText63')}}</span> </div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText64')}}</span> </div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText65')}}</span> </div>
                                            </td>
                                            <td class="text-center">
                                                <div><span>{{$t('zulutradeData.dataText66')}}</span> </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText67')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText68')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText69')}} </div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText70')}} </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText71')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText72')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText73')}}</div>
                                            </td>
                                            <td class="text-center">
                                                <div>{{$t('zulutradeData.dataText74')}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <h3>{{$t('zulutradeData.dataText75')}}</h3>
                                <p>{{$t('zulutradeData.dataText76')}}</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <h3>{{$t('zulutradeData.dataText77')}}</h3>
                                <p>{{$t('zulutradeData.dataText78')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="dualButton text-center mb-1 mt-4">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5 ms-3">{{$t('zulutradeData.dataText79')}}</router-link>
                        <a v-else :href="'/register/'" target="_blank" class="button fillBtn zulu_btn me-3">{{$t('zulutradeData.dataText79')}}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement p-4 bg-secondary">
            <div class="container max-970">
                <div class="row">
                    <div class="col-12">
                        <p class="text-center f-12 mb-0">{{$t('zulutradeData.dataText80')}} <br/>{{$t('zulutradeData.dataText81')}} <a class="undeline bold js-anchor-link" href="#Hands-off">{{$t('zulutradeData.dataText82')}}</a>  </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import $ from 'jquery'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                isHidden:false,
                isHidden1:false,
                isHidden2:false,
            };
        },
        mounted() {
            $('.js-anchor-link').click(function(e){
                e.preventDefault();
                var target = $($(this).attr('href'));
                if(target.length){
                    var scrollTo = target.offset().top;
                    $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
                }
            });
        },
    }
</script>